<template>
  <div class="pb-3">
    <div class="overflow-hidden rounded"  v-if="scripts">
      <b-overlay :show="tableLoading" bg-color="none" blur="1px">
        <b-table
          class="m-0"
          :items="scripts"
          :fields="tableFields"
          sort-icon-left
          @row-clicked="openModalScript"
          hover
          sort-by="id"
          :sort-desc="true"
          show-empty
        >
        <template #cell(id)="data">
          <b-avatar class="border-secondary">{{data.item.id}}</b-avatar>
        </template>

        <template #cell(active)="data">
          <b-button v-if="data.item.active" variant="outline-success" class="py-25 px-1">
            Active
            <b-icon icon="check2-circle" class="ml-25"/>
          </b-button>
          <b-button v-else variant="outline-danger" class="py-25 px-1" @click="changeActive(data.item)" :disabled="userCantEdit">
            Inactive
            <b-icon icon="x-circle" class="ml-25"/>
          </b-button>
        </template>

        <template #cell(namespace)="data">
          <h5>{{data.item.namespace}}</h5> 
        </template> 

        <template #cell(status)="data">
          <div class="m-0">
            <b-badge :variant="getScriptStatus(data.item.status.id)[1]">
              {{$t(getScriptStatus(data.item.status.id)[0])}}
            </b-badge> 
          </div>
        </template> 

        
        <template #cell(created_at)="data" >
          {{data.item.created_at}}
          <!-- <p class="m-0 p-0 text-secondary small">Last Updated: {{data.item.updated_at}}</p> -->
        </template>

        <template #head(actions)><div/></template>

        <template #cell(actions)="data">
           <div class="w-100px" align="right">
            <b-button variant="outline-danger" class="py-50 px-50 mr-1  " @click="openModal('delete-script', data.item)">
              <b-icon icon="trash" scale="1" />
            </b-button>          
            <b-button variant="outline-favorite " class="py-50 px-50 " :id="'rollback-btn-'+data.item.id" @click="openModal('rollback-script', data.item)">
              <b-icon icon="skip-backward-circle-fill" scale="1" rotate="180"/>
            </b-button>
          </div>
        </template>

        <template #empty>
          <h4 class="text-center text-secondary my-2">No Scripts Yet</h4>
        </template>

        </b-table>
      </b-overlay>
    </div>
    <div class="rounded overflow-hidden" v-else>
      <b-skeleton-table class="m-0" :rows="8"/>
    </div>

    <b-modal
      id="view-script"
      ok-only
      :ok-title="$t('compiled.modal.script.close')"
      size="xl"
      @hidden="currentItem={name: '', script: undefined}"
      scrollable
    >
    <div class="max-height-modal-body">
      <b-tabs lazy >
        <b-tab title="Service-Event Map" active v-if="!currentItem.script || currentItem.script.permissions">

          <transmission-map :shownScript="currentItem.script" v-if="currentItem.script"/>
          
          <div class="d-flex justify-content-center overflow-hidden" v-else>
            <div align="center" class="mx-2 w-fit-content" v-for="i in 3" :key="'transmission-skeleton-'+ i ">          
              <div align="left">
                <b-skeleton height="30px" width="200px" class="mb-0 mx-2"/>
              </div>
              <b-skeleton height="400px" width="800px"/>
            </div>
          </div>

        </b-tab>
        <b-tab title="Raw Script">

          <b-collapse :visible="true" appear @shown="canShowEditor=true" @hidden="canShowEditor=false">
            <json-editor
              v-if="currentItem.script && canShowEditor"
              :key="'script-viewer-'+uuid"
              v-model="currentItem.script"
              :modes="['code', 'tree', 'preview']"
              :height="610"
              class="mb-2"
            />
            
          </b-collapse>
        </b-tab>
      </b-tabs>
    </div>
      <template #modal-title>
        <div>
          Viewing Script: <b>{{currentItem.name}}</b>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="delete-script"
      centered
      :title="'Delete Script'"
      ok-variant="danger"
      @ok="deleteScript()"
      @hide="((e) =>{ if (modalInfo.loading) e.preventDefault()})"
      auto-focus-button="ok"
      lazy
      :ok-disabled="modalInfo.loading"
    >
      Are you sure you wish to delete this Script?
      <template #modal-ok>
        <div>
          <span v-if="!modalInfo.loading">Confirm</span>
          <b-spinner small v-else/>
        </div>
      </template>

    </b-modal>

    <b-modal
      id="rollback-script"
      centered
      :title="'Rollback to Script'"
      ok-variant="favorite"
      @ok="((e)=>{e.preventDefault(); rollbackScript() }) "
      :ok-disabled="modalInfo.loading"
      @hide="((e) =>{ if (modalInfo.loading) e.preventDefault()})"
    >
      Do you wish to rollback the Transmission to how it was when this Script was compiled?
      <span class="small d-block text-secondary" v-if="modalInfo.loading">This may take a while....</span>
      <template #modal-ok>
        <div>
          <span v-if="!modalInfo.loading">Confirm</span>
          <b-spinner small v-else/>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import custom from "@/custom";
import { makeToast } from "@/layouts/components/Popups.js";
import JsonEditor from "@/layouts/components/JsonEditor/JsonEditor.vue";
import { mapGetters } from 'vuex';
import ScriptStatus from '@/custom/class/Enum/ScriptStatus.js'
import { v4 as uuidv4 } from "uuid";
import TransmissionMap from "@/views/pages/middleware/MiddlewareManagement/Components/FluxOverview/TransmissionMap.vue";

import { 
  BRow, 
  BModal, 
  BCardText, 
  BTable, 
  BTooltip, 
  BBadge, 
  BAvatar, 
  BSpinner,
  BOverlay,
  BSkeletonTable,
  BSkeleton, 
  BButton, 
  BCol,
  BCollapse,
  BTabs,
  BTab,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BModal,
    BCardText,
    JsonEditor,
    BTable,
    BTooltip,
    BBadge,
    BAvatar,
    BSpinner,
    BOverlay,
    BSkeletonTable,
    BSkeleton,
    BCollapse,
    BTabs,
    BTab,
    TransmissionMap
  },
  data() {
    return {
      canShowEditor: false,
      optionsVisible: '',
      tableLoading: false,
      tableFields:[
        {
          key: 'id',
          label: this.$t("compiled.table.fields.hash_identifier"),
          sortable: true,
        },
        {
          key: 'namespace',
          label: this.$t("compiled.table.fields.namespace"),
          sortable: true,
        },
        {
          key: "active",
          label: this.$t("compiled.table.fields.active"),
          sortable: true,
        },
        {
          key: "created_at",
          label: this.$t("common.table.fields.created_at"),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$t("compiled.table.fields.compiling_status"),
          sortable: true,
        },
        {
          key: 'actions',
          label: this.$t("common.table.fields.actions"),
          sortable: false,
        },
      ],
      
      actions: custom.tableActions.compiledScripts,
      tableName: "scriptList",
      currentItem: { script: undefined , name: ""},
      modalInfo: {
        loading: false,
        item: null
      },
      uuid: uuidv4(),
    }

  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
  },
  computed: {
    ...mapGetters(['getTransmissionScripts']),
    scripts() {
      return this.getTransmissionScripts 
    },
    transmissionID(){
      return this.$route.params.transmissionID
    },
    userCantEdit(){
      return !this.$can('create')
    }
  },
  methods: {
    init(){

      this.$store
        .dispatch("getAllScriptsByTransmission", {transmissionID: this.transmissionID})
        .then((resp) => {
          
        })
        .catch((err) => {
          console.error(err)
        });
    },
      changeActive(item){

        if (item.status.id != 3){
          makeToast({
            title: "Failed",
            text: "Can't make Active an unfinished Script",
            variant: "danger",
            icon: "XIcon",
          });
          return
        }

        this.tableLoading = true
        this.$store
        .dispatch("changeCompiledActive", {
          item: item,
          transmissionID: this.$route.params.transmissionID,
        })
        .then(() => {
          makeToast({
            title: this.$t(`compiled.toast.active_changed.title`),
            text: this.$t(`compiled.toast.active_changed.message`),
            variant: "success",
            icon: "CheckIcon",
          });
        })
        .catch((e) => {
          makeToast(custom.errorMessages.changeCompiledActive);
        })
        .finally(()=>{
          this.tableLoading = false
        })
    },
    async fetchScriptByID(id){
      let r;
      await this.$store
        .dispatch("getCompiledScriptByID", {transmission_id: this.transmissionID, id: id})
        .then((resp) => {
          
          this.currentItem.script = JSON.parse(resp.script)
          r = resp.script
        })
        .catch((err) => {
          console.error(err)
        })
      return r
    },
    showScript: function (item) {
      const jsonScript = JSON.parse(item.script);
      this.$bvModal.show("view-script");
    },
    openModal(ref, item){
      this.modalInfo.item = item;
      this.$bvModal.show(ref);
    },
    openModalScript(item){
      if (item.status.id != 3){
        return
      }
      let id = item.id
      this.fetchScriptByID(id)
      // const jsonScript = JSON.parse(item.script);
      // this.currentItem.script = jsonScript;
      this.currentItem.name = item.namespace
      this.$bvModal.show("view-script");
    },
    rollbackScript(){
      this.modalInfo.loading = true;

      let item = this.modalInfo.item
      
      item.script_id = item.id
      
      this.$store
        .dispatch("revertCompiledScript", {
          item: item,
          transmissionID: this.$route.params.transmissionID,
        })
        .then(() => {
          makeToast({
            title: this.$t(`compiled.toast.active_changed.title`),
            text: 'Transmission Script successfully rolledback',
            variant: "success",
            icon: "CheckIcon",
          });
          this.modalInfo.loading = false
          this.$nextTick(()=>{
            this.$bvModal.hide("rollback-script");
          })
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(()=>{
          this.modalInfo.loading = false
        })
    },
    deleteScript(){
      this.modalInfo.loading = true;
      let item = this.modalInfo.item
      this.$store
        .dispatch("deleteCompiledScript", {
          item: item,
          transmissionID: this.$route.params.transmissionID,
        })
        .then(() => {
          makeToast({
            title: this.$t(`compiled.toast.active_changed.title`),
            text: 'Script successfully deleted',
            variant: "success",
            icon: "CheckIcon",
          });
          this.modalInfo.loading = false
          this.$nextTick(()=>{
            this.$bvModal.hide("delete-script");
          })
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(()=>{
          this.modalInfo.loading = false
        })
    },
    getScriptStatus(id){
      let item = new ScriptStatus().items.find(el => el.id == id)
      return [item.label, item.variant]
    },
		getRandomDecimal(){
			let r = Math.floor(Math.random()*10)
			return r
		},
  },
};
</script>

<style lang="scss" scoped>

.max-height-modal-body{
  min-height: 78vh;
}

.script-skeleton-container{
  background-color: rgba(32, 32, 32, 0.85) !important;
  box-shadow: 0 0 25px rgba(32, 32, 32, 0.7);
  height: 720px !important;
  max-height: 720px !important;
  overflow: hidden;
}
</style>

<style lang="scss">
.w-100px{
  width: 100px;
}
.ace-jsoneditor *,
textarea.jsoneditor-text * {
  font-family: dejavu sans mono, droid sans mono, consolas, monaco,
    lucida console, courier new, courier, monospace, sans-serif !important;
}

.jsoneditor {
  border: none !important;

  .jsoneditor-menu {
    &:not(ul) {
      background-color: #23a25c !important;
      border-bottom: 1px solid #23a25c !important;
      border-radius: 5px 5px 0px 0px !important;
    }

    .jsoneditor-selected {
      background-color: #23a25c !important;
    }
  }

  .ace_editor {
    border: solid 1px #202020;
    border-bottom: none;
    border-top: none;
  }
}

</style>
